
/*
 *   File : family-request.js
 *   Author URI : https://evoqins.com
 *   Description : Card for showing family details 
 *   Integrations : null
 *   Version : v1.1
 */

// import packages
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// import components
import { GradientButton, OutlineButton } from "../CTA";

import APIService from "../../Service/api-service";

export default function FamilyRequestCard(props) {
    const { type, data } = props;
    const navigator = useNavigate();

    const USER_DATA = useSelector(state => state.generalData.PROFILE_DATA);

    const [acceptLoader, setAcceptLoader] = useState(false);
    const [rejectLoader, setRejectLoader] = useState(false);

    function _navigateToDetail() {
        navigator('detail', { state: { id: data.family_detail_id } });
    }

    function _handleAccept(data) {
        setAcceptLoader(true);
        let url = `family/accept?token=${data.token}&request_id=${data.family_id}&type=1`;
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                props.handleJoiningRequestCallBack(true);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setAcceptLoader(false);
        })
    }

    function _handleReject(data) {
        setRejectLoader(true);
        let url = `family/reject?token=${data.token}&request_id=${data.family_id}&type=1`;
        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                props.handleJoiningRequestCallBack(false);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setRejectLoader(false);
        })
    }

    function _getShortName() {
        let name_array = data.name.split(" ");
        return name_array.length > 1 ? name_array[0][0] + name_array[1][0] : name_array[0][0];
    }

    function FamilyMemberDetail() {
        return (
            <div className="row">
                <div className="col-lg-10 col-12">
                    <div className="d-flex flex-wrap row-gap-16px">
                        <div className="pe-5 border-right-1px-md">
                            <label className="e-poppins-medium e-font-14 color-outer-space">Joined on</label>
                            <p className="e-poppins-medium e-font-14 color-black mb-0">{data.joined}</p>
                        </div>
                        <div className="ps-md-3 pe-5 border-right-1px-md">
                            <label className="e-poppins-medium e-font-14 color-outer-space">Total invested</label>
                            <p className="e-poppins-medium e-font-14 color-black mb-0">
                                <span className="e-inter-medium">₹</span>{data.invested_amount?.toLocaleString('en-IN')}
                            </p>
                        </div>
                        <div className="ps-md-3 pe-5 border-right-1px-md">
                            <label className="e-poppins-medium e-font-14 color-outer-space">Current value</label>
                            <p className="e-poppins-medium e-font-14 color-black mb-0">
                                <span className="e-inter-medium">₹</span>{data.current_amount?.toLocaleString('en-IN')}
                            </p>
                        </div>
                        <div className="ps-md-3 pe-5 border-right-1px-md">
                            <label className="e-poppins-medium e-font-14 color-outer-space">Current returns</label>
                            <p className="e-poppins-medium e-font-14 color-black mb-0">
                                <span className="e-inter-medium">₹</span>{data.returns_amount?.toLocaleString('en-IN')}
                            </p>
                        </div>
                        <div className="ps-md-3">
                            <label className="e-poppins-medium e-font-14 color-outer-space">Returns %</label>
                            <p className={`e-poppins-medium e-font-14  mb-0 ${data.returns_percentage >= 0 ? 'color-sea-green' : 'color-red'}`}>
                                {data.returns_percentage}
                            </p>
                        </div>
                    </div>
                </div>
                {
                    USER_DATA.family_admin === true &&
                    <div className="col-lg-2 text-lg-end text-start mt-lg-0 mt-4">
                        <GradientButton label="View detail"
                            className="py-2 px-3"
                            handleClick={_navigateToDetail} />
                    </div>
                }

            </div>
        )
    }

    function NewRequestDetail() {
        return (
            <div className="row">
                <div className="col-lg-8 ">
                    <div className="d-flex flex-wrap row-gap-16px">
                        <div className="pe-5 border-right-1px-md">
                            <label className="e-poppins-medium e-font-14 color-outer-space">Invited on</label>
                            <p className="e-poppins-medium e-font-14 color-black mb-0">{data.invited_on}</p>
                        </div>

                    </div>
                </div>
                <div className="col-lg-4 text-lg-end mt-lg-0 mt-4">
                    <OutlineButton label="Reject"
                        className="me-3 px-3 color-black"
                        loading={rejectLoader}
                        handleClick={() => _handleReject(data)} />
                    <GradientButton label="Accept request"
                        className="py-2 px-3"
                        loading={acceptLoader}
                        handleClick={() => _handleAccept(data)} />
                </div>
            </div>
        )
    }

    return (
        <div className="bg-pure-white padding-24px-all border-bright-gray border-all border-radius-24px">
            <div className={`row mb-3`}>
                <div className="col-12">
                    <div className="d-flex align-items-center">
                        {
                            data.name &&
                            <div className="bg-ocean-boat-blue text-uppercase d-flex justify-content-center color-white  align-items-center border-radius-100 e-inter-bold e-font-20 h-64px w-64px  padding-12px-tb-sm-8">
                                {_getShortName()}
                            </div>
                        }

                        <p className="e-poppins-medium e-font-16 color-black mb-0 ms-2 text-capitalize">{type === 1 ? data.name : data.invited_by_name}</p>

                        <p className="color-gray e-poppins-medium e-font-14 mb-0 ms-2 mt-1">Client ID:
                            <span className="color-black ms-1">{ type === 1 ? data.mt_client_id : data.invited_by}</span>
                        </p>
                    </div>
                </div>

            </div>
            {
                type === 1 ?
                    <FamilyMemberDetail />
                    :
                    <NewRequestDetail />
            }
        </div>
    )
};