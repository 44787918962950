
/*
 *   File : detail.js
 *   Author URI : https://evoqins.com
 *   Description : Display all the details of selected family member.
 *   Integrations : NA
 *   Version : v1.1
 */

// import packages
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// import components
import { InvestmentHeader } from "../../Component/Header";
import Footer from "../../Component/Footer/footer";
import { CustomLoader, EmptyScreen } from "../../Component/Other";
import { BreadCrumb } from "../../Component/BreadCrumb";
import { CustomTab } from "../../Component/Tab";
import { GradientButton, OutlineButton } from "../../Component/CTA";
import { PortfolioOptions } from "../../Component/Popups";
import { FamilyImportFund } from "../../Component/Modal";
import DataTableContainer from "../../Component/Form-elements/data-table";

import { useClickOutside } from "../../Helper";
import APIService from "../../Service/api-service";

const PORTFOLIO_STYLES = {
    headRow: {
        style: {
            fontSize: "16px",
            lineHeight: "18px"
        }
    },
    headCells: {
        style: {
            '&:not(:first-child)': {
                justifyContent: 'center',
            },
        },
    },
    rows: {
        style: {
            '&:hover': {
                boxShadow: '0px 12px 16px 0px rgba(50, 50, 71, 0.08)',
            },
        },
    },
    cells: {
        style: {
            justifyContent: "center",
        }
    }
};

const PAGE_NAVIGATION = [
    { page: 'Home', url: '/' },
    { page: 'Manage family', url: '/manage-family' },
    { page: 'Family member detail', url: null },
];

const DETAIL_TYPE = ['Investments', 'Profile'];
const INVESTMENT_TYPE = ['Mutual funds', 'Life goals', 'External fund'];

const ENABLED_TRANSACTIONS = {
    lumpsum_enabled: false,
    sip_enabled: false,
    swp_enabled: true,
    switch_enabled: true,
    stp_enabled: true,
    redeem: false
}

const MF_DATA = [
    {
        fund_name: 'HDFC Overnight Fund - Direct Plan - Growth Option',
        image: 'https://mastertrust-static-assets.s3.ap-south-1.amazonaws.com/amcs/hdfc.png',
        category: 'Equity small cap',
        invested_amount: 100000,
        current_amount: 150000,
        pl: '24.16(+9.20)',
        xirr: '50.190%'
    },
    {
        fund_name: 'ICICI Pru BHARAT 22 FOF (G)',
        image: 'https://mastertrust-static-assets.s3.ap-south-1.amazonaws.com/amcs/icici.png',
        category: 'Equity small cap',
        invested_amount: 100000,
        current_amount: 150000,
        pl: '24.16(+9.20)',
        xirr: '50.190%'
    },
];


const GOAL_DATA = [
    {
        goal_name: 'Retirement',
        image: 'https://master-trust.s3.ap-south-1.amazonaws.com/goals/goals_icons/Hand+Money.png',
        category: 'Equity small cap',
        invested_amount: 100000,
        current_amount: 150000,
        pl: '24.16(+9.20)',
        xirr: '50.190%'
    },
    {
        goal_name: 'Dream home',
        image: 'https://master-trust.s3.ap-south-1.amazonaws.com/goals/goals_icons/Home.png',
        category: 'Equity small cap',
        invested_amount: 100000,
        current_amount: 150000,
        pl: '24.16(+9.20)',
        xirr: '50.190%'
    },
];

export default function FamilyMemberDetail(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const optionsRef = useRef();
    const [apiLoader, setApiLoader] = useState(false);
    const [pageLoader, setPageLoader] = useState(true);
    const [detailType, setDetailType] = useState(1);
    const [investmentType, setInvestmentType] = useState(1);
    const [openFundImport, setOpenFundImport] = useState(false);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [portfolioData, setPortfolioData] = useState({});
    const [summaryData, setSummaryData] = useState({});
    const [profileData, setProfileData] = useState({});
    const [casImportEnabled, setCASImportEnabled] = useState(false);

    const MF_COLUMNS = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: true,
            width: "350px",
            sortFunction: (a, b) => _sortFundName(a, b, 'fund_name'),
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name">
                    {
                        row['image'] !== '-' &&
                        <div className="padding-6px-all border-radius-8px border-all border-bright-gray w-unset">
                            <img src={row['image']} alt={row['fund_name']}
                                width={46}
                                height={46}
                                className="object-fit-contain" />
                        </div>
                    }

                    <div>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-2 bg-white">{row['fund_name']}</p>
                        <div className="d-flex flex-wrap gap-8px ">
                            <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px">
                                {row['category']}
                            </span>
                        </div>
                    </div>

                </div>
        },
        {
            name: 'Invested amount',
            selector: row => row['invested_amount'],
            sortable: true,
            width: '170px',
            cell: row =>
                <p className={`color-black e-poppins-medium e-font-14 line-height-18px mb-0 color-black`}>
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current amount',
            selector: row => row['current_amount'],
            sortable: true,
            width: '150px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0 color-black`}>
                    <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'P&L',
            selector: row => row['returns'],
            sortable: true,
            width: '120px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0 ${row['returns'] < 0 ? "color-red" : "color-sea-green"}`}>
                    {`${row['returns'].toLocaleString('en-IN')}(${row['returns_percentage']}%)`}
                </p>
        },
        {
            name: 'XIRR',
            selector: row => row['xirr'],
            sortable: true,
            width: '80px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0 ${row['xirr'] < 0 ? "color-red" : "color-sea-green"}`}>
                    {row['xirr']}%
                </p>
        },
        // {
        //     name: '',
        //     sortable: true,
        //     width: '300px',
        //     cell: (row, index) =>
        //         <div className="d-flex">
        //             <OutlineButton label="Redeem"
        //                 className="padding-10px-tb padding-16px-lr" />
        //             <GradientButton label="Invest more"
        //                 className="ms-2 padding-10px-tb padding-16px-lr" />
        //             <div className="position-relative">

        //                 <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
        //                     width={24}
        //                     height={24}
        //                     onClick={() => _showOptions(index)} />
        //                 {
        //                     selectedRow === index &&
        //                     <div ref={optionsRef}>
        //                         <PortfolioOptions data={ENABLED_TRANSACTIONS}
        //                             id={`option-id-${index}`}
        //                             redeem={() => _handleRedeem(row)}
        //                             invest={_handleInvest}
        //                             swp={() => _handleSWP(row)}
        //                             stp={() => _handleSTP(row)}
        //                             switch={() => _handleSwitch(row)}
        //                             fromDetail={true} />
        //                     </div>
        //                 }

        //             </div>
        //         </div>
        // },
    ];

    const MF_COLUMNS_MOBILE = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: true,
            width: "60%",
            sortFunction: (a, b) => _sortFundName(a, b, 'fund_name'),
            cell: row =>
                <div>
                    <p className="color-black e-poppins-medium e-font-12 line-height-24px mb-2 bg-white">{row['fund_name']}</p>
                </div>
        },
        {
            name: 'Current(Invested)',
            selector: row => row['current_amount'],
            sortable: false,
            width: '35%',
            cell: (row, index) =>
                <div className="d-flex">
                    <div className="text-center">
                        <p className={`color-black e-poppins-semi-bold e-font-12 line-height-18px mb-0 ${row['current_amount'] < 0 ? "color-red" : "color-sea-green"}`}>
                            <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
                        <p className={`color-black e-poppins-medium e-font-12 line-height-18px mb-0 color-black`}>
                            <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
                    </div>
                </div>
        },
        // {
        //     name: '',
        //     selector: row => row['current_amount'],
        //     sortable: false,
        //     width: '2%',
        //     cell: (row, index) =>
        //         <div className="position-relative h-100">
        //             <div className="d-flex align-items-top h-100">
        //                 <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
        //                     width={18}
        //                     height={18}
        //                     onClick={() => _showOptions(row, index)} />
        //             </div>
        //             {
        //                 selectedRow === index &&
        //                 <div ref={optionsRef}>
        //                     <PortfolioOptions data={row}
        //                         id={`option-id-${index}`}
        //                         redeem={() => _handleRedeem(row)}
        //                         invest={_handleInvest}
        //                         swp={() => _handleSWP(row)}
        //                         stp={() => _handleSTP(row)}
        //                         switch={() => _handleSwitch(row)} />
        //                 </div>
        //             }

        //         </div>
        // }
    ];

    const GOAL_COLUMNS = [
        {
            name: 'Goal name',
            selector: row => row['name'],
            sortable: true,
            width: "350px",
            sortFunction: (a, b) => _sortFundName(a, b, 'name'),
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name">
                    <div className="padding-6px-all border-radius-8px border-all border-bright-gray w-unset">
                        <img src={row['image']} alt={row['name']}
                            width={46}
                            height={46}
                            className="object-fit-contain" />
                    </div>
                    <div>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-2 bg-white">{row['name']}</p>
                    </div>

                </div>
        },
        {
            name: 'Invested amount',
            selector: row => row['invested_amount'],
            sortable: true,
            width: '170px',
            cell: row =>
                <p className={`color-black e-poppins-medium e-font-14 line-height-18px mb-0 color-black`}>
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current amount',
            selector: row => row['current_amount'],
            sortable: true,
            width: '150px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0 color-black`}>
                    <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'P&L',
            selector: row => row['returns'],
            sortable: true,
            width: '120px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold text-start e-font-16 line-height-18px mb-0 ${row['returns'] < 0 ? "color-red" : "color-sea-green"}`}>
                    {`${row['returns'].toLocaleString('en-IN')}(${row['returns_percentage']}%)`}
                </p>
        },
        {
            name: 'XIRR',
            selector: row => row['xirr'],
            sortable: true,
            width: '80px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0 ${row['xirr'] < 0 ? "color-red" : "color-sea-green"}`}>
                    {row['xirr']}%
                </p>
        },
        // {
        //     name: '',
        //     sortable: true,
        //     width: '300px',
        //     cell: (row, index) =>
        //         <div className="d-flex">
        //             <OutlineButton label="Redeem"
        //                 className="padding-10px-tb padding-16px-lr" />
        //             <GradientButton label="Invest more"
        //                 className="ms-2 padding-10px-tb padding-16px-lr" />
        //             <div className="position-relative">

        //                 <img src={require("../../Assets/Images/profile/bank-menu-dots.png")}
        //                     width={24}
        //                     height={24}
        //                     onClick={() => _showOptions(index)} />
        //                 {
        //                     selectedRow === index &&
        //                     <div ref={optionsRef}>
        //                         <PortfolioOptions data={ENABLED_TRANSACTIONS}
        //                             id={`option-id-${index}`}
        //                             redeem={() => _handleRedeem(row)}
        //                             invest={_handleInvest}
        //                             swp={() => _handleSWP(row)}
        //                             stp={() => _handleSTP(row)}
        //                             switch={() => _handleSwitch(row)} />
        //                     </div>
        //                 }

        //             </div>
        //         </div>
        // },
    ];

    const GOAL_COLUMNS_MOBILE = [
        {
            name: 'Goal',
            selector: row => row['name'],
            sortable: true,
            width: "60%",
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name">
                    <p className="color-black e-poppins-medium e-font-12 line-height-16px mb-0 bg-white">{row['name']}</p>
                </div>
        },
        {
            name: <div className="d-flex justify-content-end w-100">Current(Invested)</div>,
            selector: row => row['current_amount'],
            sortable: false,
            width: '40%',
            cell: (row, index) =>
                <div className="text-end  w-100">
                    <p className={`color-black e-poppins-semi-bold e-font-12 line-height-18px mb-0 ${row['current_amount'] < 0 ? "color-red" : "color-sea-green"}`}>
                        <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
                    <p className={`color-black e-poppins-medium e-font-12 line-height-18px mb-0 color-black`}>
                        <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>

                </div>
        }
    ];

    const EXTERNAL_COLUMNS = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: true,
            width: "350px",
            sortFunction: (a, b) => _sortFundName(a, b, 'fund_name'),
            cell: row =>
                <div className="d-flex align-items-center gap-12px e-fund-name">
                    {
                        row.image !== '-' &&
                        <div className="padding-6px-all border-radius-8px border-all border-bright-gray w-unset">
                            <img src={row['image']} alt={row['fund_name']}
                                width={46}
                                height={46}
                                className="object-fit-contain" />
                        </div>
                    }

                    <div>
                        <p className="color-black e-poppins-medium e-font-16 line-height-24px mb-2 bg-white">{row['fund_name']}</p>
                        <div className="d-flex flex-wrap gap-8px ">
                            <span className="border-radius-16px border-all border-bright-gray py-1 padding-12px-lr color-black e-poppins-medium e-font-10 line-height-18px">
                                {row['category']}
                            </span>
                        </div>
                    </div>

                </div>
        },
        {
            name: 'Invested amount',
            selector: row => row['invested_amount'],
            sortable: true,
            width: '170px',
            cell: row =>
                <p className={`color-black e-poppins-medium e-font-14 line-height-18px mb-0 color-black`}>
                    <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'Current amount',
            selector: row => row['current_amount'],
            sortable: true,
            width: '150px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold e-font-16 line-height-18px mb-0 color-black`}>
                    <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
        },
        {
            name: 'P&L',
            selector: row => row['returns'],
            sortable: true,
            left: true,
            width: '200px',
            cell: row =>
                <p className={`color-black e-poppins-semi-bold text-start e-font-16 line-height-18px mb-0 ${row['returns'] < 0 ? "color-red" : "color-sea-green"}`}>
                    {`${row['returns'].toLocaleString('en-IN')}(${row['returns_percentage']}%)`}
                </p>
        }
    ];

    const EXTERNAL_COLUMNS_MOBILE = [
        {
            name: 'Fund name',
            selector: row => row['fund_name'],
            sortable: true,
            width: "65%",
            sortFunction: (a, b) => _sortFundName(a, b, 'fund_name'),
            cell: row =>
                <div>
                    <p className="color-black e-poppins-medium e-font-12 line-height-24px mb-2 bg-white">{row['fund_name']}</p>
                </div>
        },
        {
            name: 'Current(Invested)',
            selector: row => row['current_amount'],
            sortable: false,
            width: '35%',
            cell: (row, index) =>
                <div className="d-flex">
                    <div className="text-center">
                        <p className={`color-black e-poppins-semi-bold e-font-12 line-height-18px mb-0 ${row['current_amount'] < 0 ? "color-red" : "color-sea-green"}`}>
                            <span className="e-inter-semi-bold ">₹</span>{row['current_amount'].toLocaleString('en-IN')}</p>
                        <p className={`color-black e-poppins-medium e-font-12 line-height-18px mb-0 color-black`}>
                            <span className="e-inter-medium ">₹</span>{row['invested_amount'].toLocaleString('en-IN')}</p>
                    </div>
                </div>
        }
    ];

    useEffect(() => {

        function _handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', _handleResize);

        window.removeEventListener('resize', _handleResize);
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            _getMemberDetail();
        } else {
            toast.dismiss();
            toast.error('Please select member to see detail');
            navigate(-1);
        }
    }, [location]);

    useEffect(() => {
        if (openFundImport === true) {
            _openFundModal();
        }
    }, [openFundImport]);


    useClickOutside(optionsRef, () => {
        setSelectedRow(-1);
    });

    // handle open fund import
    function _openFundModal() {
        const modal = new Modal(document.getElementById("family-initiate-external-funds-modal"), {});
        modal.show();
    }

    // handle detail type tab change
    function _handleDetailTypeTab(value) {
        setDetailType(value);
        if (value === 1) {
            _getMemberDetail()
        } else {
            _getProfileData();
        }
    }

    // handle investment type tab
    function _handleInvestmentTypeTab(value) {
        setInvestmentType(value);
    }

    // handle external fund button
    function _handleExternalFundButton() {
        setOpenFundImport(true);
    }

    const _sortFundName = (rowA, rowB, key) => {
        const valueA = String(rowA[key]).toLowerCase();
        const valueB = String(rowB[key]).toLowerCase();

        if (valueA > valueB) {
            return 1;
        }

        if (valueB > valueA) {
            return -1;
        }
        return 0;
    };

    function _handleRedeem(data) {
    }

    function _handleSWP(data) {
    }

    function _handleSTP(data) {
    }

    function _handleSwitch(data) {
    }

    function _handleInvest(data) {

    }

    // close fund modal
    function _handleInitiateClose() {
        setOpenFundImport(false);
    }


    function _showOptions(index) {
        if (MF_DATA.length - 1 === index) {
            let options = document.getElementById(`option-id-${index}`);
            console.log('optionss', options);
            if (options) {
                options.style.top = '-100%';
            }
        }
        setSelectedRow(index);
    }

    // API - get family detail
    function _getMemberDetail(id) {
        setApiLoader(true);
        let url = 'family/portfolio';
        let payload = JSON.stringify({
            family_detail_id: location.state.id,
            type: investmentType
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data.portfolio_returns.portfolio.self_details);
                setPortfolioData(response.data.portfolio_returns.investment_details);
                setCASImportEnabled(response.data.portfolio_returns.is_cas_import_allowed);
            } else {
                setPortfolioData({});
                setSummaryData({});
                toast.error(response.message);
            }
            setApiLoader(false);
            setPageLoader(false);
        })
    }

    // API - get profile detail of the member
    function _getProfileData() {
        setApiLoader(true);
        let url = 'family/profile';
        let payload = JSON.stringify({
            family_detail_id: location.state.id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setProfileData(response.data);
            } else {
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // common inline component for personal information
    const PersonalInformation = (props) => {
        return (
            <>
                <p className="color-black e-poppins-regular e-font-14 line-height-14px mb-2">{props.heading}</p>
                <p className="color-black e-poppins-medium e-font-14 line-height-22px mb-0 word-break-all">{props.description}</p>
            </>
        )
    }

    const InvestmentSummary = (props) => {
        return (
            <div className="bg-light-powder-blue border-radius-16px padding-24px-all">
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="e-poppins-medium e-font-14 line-height-20px color-outer-space">{props.heading}</h4>
                    <img draggable={false} src={props.icon}
                        alt="User info"
                        width={40}
                        height={40}
                    />
                </div>

                <p className={`${props.descriptionColor} e-poppins-bold e-font-24 mb-0`}>{props.rupee && <span className="e-inter-bold">₹</span>} {props.description}</p>
            </div>
        )
    }
    return (
        <>
            <div className="e-page-container e-portfolio margin-118px-bottom">
                {/* Header */}
                <InvestmentHeader />

                {
                    pageLoader === true ? (
                        <div className='h-80vh '>
                            <CustomLoader />
                        </div>
                    ) : (
                        <>
                            <BreadCrumb data={PAGE_NAVIGATION} />

                            {/* Header and detail type */}
                            <div className="row">
                                <div className="col-12">
                                    <h1 className="color-black e-poppins-semi-bold e-font-20 line-height-24px mt-4 mb-3 me-3 margin-block-end-0">
                                        Family member detail
                                    </h1>
                                </div>
                                <div className="col-12">
                                    <CustomTab data={DETAIL_TYPE}
                                        wrapperClass={'gap-16px'}
                                        className="px-0 padding-10px-tb e-order-tab mb-4"
                                        onSelectTab={_handleDetailTypeTab} />
                                </div>
                            </div>
                            {
                                // ----- Investment details -----//
                                detailType === 1 ?
                                    (
                                        <div className="row">
                                            <div className="col-12">
                                                <h4 className="color-black e-poppins-semi-bold e-font-20 line-height-24px mb-3">
                                                    Investment summary
                                                </h4>
                                            </div>

                                            {
                                                Object.keys(summaryData).length &&
                                                <>
                                                    <div className="col-md-3 col-sm-6">
                                                        <InvestmentSummary heading="Invested amount"
                                                            descriptionColor="color-black"
                                                            icon={require('../../Assets/Images/family/registered.svg').default}
                                                            rupee={true}
                                                            description={summaryData.invested_amount?.toLocaleString('en-IN')} />
                                                    </div>
                                                    <div className="col-md-3 col-sm-6 mt-sm-0 mt-4">
                                                        <InvestmentSummary heading="Current value"
                                                            descriptionColor="color-black"
                                                            icon={require('../../Assets/Images/family/ac-opned.svg').default}
                                                            rupee={true}
                                                            description={summaryData.current_value?.toLocaleString('en-IN')} />
                                                    </div>
                                                    <div className="col-md-3 col-sm-6 mt-md-0 mt-4">
                                                        <InvestmentSummary heading="P&L"
                                                            descriptionColor={summaryData.returns < 0 ? "color-red" : "color-sea-green"}
                                                            icon={require('../../Assets/Images/family/invested.svg').default}
                                                            rupee={false}
                                                            description={`${summaryData.returns?.toLocaleString('en-IN')}(${summaryData.returns_percentage}%)`} />
                                                    </div>
                                                    <div className="col-md-3 col-sm-6 mt-md-0 mt-4">
                                                        <InvestmentSummary heading="XIRR"
                                                            descriptionColor={summaryData.xirr < 0 ? "color-red" : summaryData.xirr > 0 ? "color-sea-green" : "color-black"}
                                                            icon={require('../../Assets/Images/family/not-invested.svg').default}
                                                            rupee={false}
                                                            description={`${summaryData.xirr}%`} />
                                                    </div>
                                                </>
                                            }

                                            {
                                                casImportEnabled === true &&
                                                <div className="col-12 mt-5">
                                                    <div className="bg-ivory border-radius-20px padding-24px-all">
                                                        <div className="row">
                                                            <div className="col-sm-8">
                                                                <h4 className="e-poppins-medium e-font-32-sm-24 line-height-48px-sm-32px color-black">
                                                                    Within a few minutes, you may monitor and analyze all of your mutual fund holdings.
                                                                </h4>
                                                                <GradientButton label="Import external fund"
                                                                    icon="refresh-external-case"
                                                                    className="padding-12px-tb padding-16px-lr mt-3"
                                                                    iconSize={24}
                                                                    iconClass="ms-2"
                                                                    handleClick={_handleExternalFundButton} />
                                                            </div>
                                                            <div className="col-sm-4 d-sm-block d-none text-end">
                                                                <img src={require('../../Assets/Images/family/import-funds.png')}
                                                                    alt=""
                                                                    draggable={false}
                                                                    className="w-213px h-100" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-12 mt-5">
                                                <h4 className="color-black e-poppins-semi-bold e-font-20 line-height-24px">
                                                    Your Investments
                                                </h4>
                                            </div>
                                            <div className="col-12 mt-4">
                                                <CustomTab data={INVESTMENT_TYPE}
                                                    wrapperClass={'gap-16px'}
                                                    className="px-0 padding-10px-tb e-order-tab mb-4"
                                                    onSelectTab={_handleInvestmentTypeTab} />
                                            </div>
                                            {
                                                apiLoader === true ?
                                                    <div className="col-12">
                                                        <CustomLoader />
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            investmentType === 1 ? (
                                                                <div className="col-12 mt-4">
                                                                    {
                                                                        Object.keys(portfolioData).length > 0 && portfolioData.mutual_fund.length > 0 ?
                                                                            <div className="e-portfolio-funds e-mobile-table mt-sm-0 mt-2">
                                                                                <DataTableContainer columns={screenWidth > 575 ? MF_COLUMNS : MF_COLUMNS_MOBILE}
                                                                                    id="fund-table-portfolio"
                                                                                    data={portfolioData.mutual_fund}
                                                                                    pagination={false}
                                                                                    customStyles={PORTFOLIO_STYLES} />
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <EmptyScreen image={require('../../Assets/Images/nodata/sip.svg').default}
                                                                                    title="Not yet invested in mutual funds?"
                                                                                    description="Invest in mutual funds for better returns" />
                                                                            </div>
                                                                    }

                                                                </div>
                                                            ) : investmentType === 2 ? (
                                                                <div className="col-12 mt-4">
                                                                    {
                                                                        Object.keys(portfolioData).length > 0 && portfolioData.life_goals.length > 0 ?
                                                                            <div className="e-portfolio-funds e-mobile-table mt-sm-0 mt-2">
                                                                                <DataTableContainer columns={screenWidth > 575 ? GOAL_COLUMNS : GOAL_COLUMNS_MOBILE}
                                                                                    id="fund-table-portfolio"
                                                                                    data={GOAL_DATA}
                                                                                    pagination={false}
                                                                                    customStyles={PORTFOLIO_STYLES} />
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <EmptyScreen image={require('../../Assets/Images/nodata/sip.svg').default}
                                                                                    title="Not yet done your goal-based planning?"
                                                                                    description="Plan your future by investing in your life goals." />
                                                                            </div>
                                                                    }

                                                                </div>
                                                            ) : (
                                                                <div className="col-12 mt-4">
                                                                    {
                                                                        Object.keys(portfolioData).length > 0 && portfolioData.external_funds.length > 0 ?
                                                                            <div className="e-portfolio-funds e-mobile-table mt-sm-0 mt-2">
                                                                                <DataTableContainer columns={screenWidth > 575 ? EXTERNAL_COLUMNS : EXTERNAL_COLUMNS_MOBILE}
                                                                                    id="fund-table-portfolio"
                                                                                    data={portfolioData.external_funds}
                                                                                    pagination={false}
                                                                                    customStyles={PORTFOLIO_STYLES} />
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                <EmptyScreen image={require('../../Assets/Images/nodata/sip.svg').default}
                                                                                    title="No external investment found" />
                                                                            </div>
                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                    </>
                                            }
                                        </div>
                                    )
                                    :
                                    // ----- Profile details -----//
                                    (
                                        <>
                                            {
                                                Object.keys(profileData).length !== 0 &&
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="bg-light-pastel-blue-gray border-bright-gray border-all border-radius-16px padding-24px-tb padding-32px-lr">
                                                            <div className="d-flex align-items-center">
                                                                <div className="bg-ocean-boat-blue  d-flex justify-content-center color-light-cyan  align-items-center border-radius-100 e-inter-bold e-font-22-sm-18 h-72px w-72px  padding-12px-tb-sm-8">
                                                                    {profileData.short_name}
                                                                </div>
                                                                <div className="ms-3">
                                                                    <h4 className="e-poppins-semi-bold e-font-18 line-height-22px color-black text-capitalize">{profileData.name}</h4>
                                                                    <p className="e-poppins-medium e-font-14 color-gray mb-0">Client ID: <span className="color-icon-black">{profileData.mastertrust_id}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-5">
                                                        <div className="border-radius-16px border-all border-bright-gray padding-32px-lr py-4">
                                                            <div className="d-flex justify-content-between">
                                                                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">Personal information</p>

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="row">
                                                                        <div className="col-lg-2 col-md-3 col-6 margin-24px-top" >
                                                                            <PersonalInformation heading="Name"
                                                                                description={profileData.account_holder_name} />
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-3 col-6 margin-24px-top" >
                                                                            <PersonalInformation heading="Date of birth"
                                                                                description={profileData.dob} />
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-3 col-6 margin-24px-top" >
                                                                            <PersonalInformation heading="Gender"
                                                                                description={profileData.gender} />
                                                                        </div>
                                                                        <div className="col-lg-2 col-md-3 col-6 margin-24px-top">
                                                                            <PersonalInformation heading="Marital status"
                                                                                description={profileData.marital_status} />
                                                                        </div>
                                                                        <div className="col-lg-4 col-md-3 col-6 margin-24px-top">
                                                                            <PersonalInformation heading="Mobile"
                                                                                description={profileData.mobile} />
                                                                        </div>
                                                                        <div className="col-lg-7 col-md-7 col-12 margin-32px-top">
                                                                            <PersonalInformation heading="Address"
                                                                                description={profileData.address} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mt-3">
                                                        <div className="border-radius-16px border-all border-bright-gray padding-32px-lr py-4">
                                                            <div className="d-flex justify-content-between">
                                                                <p className="color-black e-poppins-medium e-font-16 line-height-16px mb-0">Bank details</p>
                                                            </div>
                                                            {
                                                                profileData.banks.map((item, key) => {
                                                                    return (
                                                                        <div className="d-flex align-items-center mt-4" key={key}>
                                                                            <div className="padding-6px-all border-radius-8px border-all border-bright-gray w-unset">
                                                                                <img src={item.bank_image}
                                                                                    alt=""
                                                                                    width={46}
                                                                                    height={46}
                                                                                    className="object-fit-contain" />
                                                                            </div>
                                                                            <div className="ms-3">
                                                                                <h5 className="e-poppins-semi-bold e-font-16 color-black">{item.bank_name}</h5>
                                                                                <p className="e-poppins-medium e-font-16 color-outer-space mb-0">{item.masked_account_number}</p>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )
                            }
                        </>
                    )
                }
            </div>
            {
                openFundImport === true &&
                <FamilyImportFund close={_handleInitiateClose}
                    callBackExternalFund={_getMemberDetail} />
            }
        </>
    )
}