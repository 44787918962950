/*
 *   File :  invite-family.js
 *   Author URI : https://evoqins.com
 *   Description : UI component which allows the existing Master Trsut user to invite or link another Master Trsut account to the Family segment.
 *   Integrations : null
 *   Version : v1.1
 */

import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Icon from "../Icon/icon";
import { CustomTextInput, CustomPhoneInput } from "../Form-elements";
import { GradientButton } from "../CTA";
import { OTPInput } from "../Form-elements";

import style from "../../Styles/Component/invite-family.module.scss"
import APIService from "../../Service/api-service";

const InviteMemberModal = (props) => {

    // state variables to store data
    const [apiLoader, setApiLoader] = useState(false);
    const [mastertrustId, setmastertrustId] = useState("");
    const [mastertrustIdError, setmastertrustIdError] = useState("");
    const [panNumber, setPanNumber] = useState("");
    const [panNumberError, setPanNumberError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [verified, setVerified] = useState(false);
    const [pinString, setPinString] = useState("");
    const [pinError, setPinError] = useState("");
    const [seconds, setSeconds] = useState(30);
    const [relation, setRelation] = useState('');

    useEffect(() => {
        var my_modal = document.getElementById("invite-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.close();
        });
    }, []);

    useEffect(() => {
        
        const countdown = setInterval(() => {
            if (seconds > 0 && verified === true) {
                setSeconds(seconds - 1);
            } else {
                clearInterval(countdown);
                // Handle expiration logic here (e.g., show a resend button)
            }
        }, 1000);

        return () => {
            clearInterval(countdown);
        };
    }, [seconds, verified]);

    // input fields handler
    const _handleInputField = (type, value) => {
        switch (type) {
            case 1:
                setmastertrustId(value);
                setmastertrustIdError("");
                break;
            case 2:
                setPanNumber(value);
                setPanNumberError("");
                break;
        }
    }

    const _handlePin = (value) => {
        setPinString(value);
        setPinError("");
    }

    const _handleInput = (value) => {
        setPhoneNumber(value);
        setPhoneError("");
    }

    // validation handler
    const _handleValidation = () => {
        let valid = true;
        if (mastertrustId.length < 3) {
            setmastertrustIdError("Enter valid master trust id")
            valid = false
        }
        if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(panNumber)) {
            valid = false;
            setPanNumberError("Enter valid PAN number");
        }
        if (phoneNumber.length < 10) {
            valid = false;
            setPhoneError('Phone number required');
        }
        if (valid === true) {
            _addMember();
        }
    }

    function _validateOTP (otp) {
        if (otp.length !== 4) {
            setPinError('Invalid OTP')
            return false;
        }else{
            return true;
        }
    }

    // API - add family member
    function _addMember() {
        setApiLoader(true);
        let url = 'family/add';
        let payload = JSON.stringify({
            client_id: mastertrustId,
            pan: panNumber,
            phone_number: phoneNumber
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setRelation(response.data.relation);
                toast.dismiss();
                toast.success(`Invite mail sended to ${response.data.email}`);
                // setVerified(true);
                document.getElementById("modal-close").dispatchEvent(new Event("click"));
            }else{
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    // API - verify OTP
    async function _verifyPin () {
        let valid = await _validateOTP(pinString);
        if (valid !== true) {
            return;
        }
        setApiLoader(true);
        let url = 'family/verify-otp';
        let payload = JSON.stringify({
            otp: pinString,
            country_code: '+91',
            phone_number: phoneNumber,
            relation
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Member Added successfully');
                document.getElementById("modal-close").dispatchEvent(new Event("click"));
                props.sendInvite();
            }else{
                toast.dismiss();
                toast.error(response.message);
            }
            setApiLoader(false);
        })
    }

    function _handleResend() {
        let url = 'family/resend-otp';
        let payload = JSON.stringify({
            country_code: '+91',
            phone_number: phoneNumber,
            relation
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(`Invite mail sended to ${response.data.email}`);

                // setPinString("");
                // setSeconds(30);
            } 
            else {
                toast.dismiss();
                toast.error(response.message);
            }
        })
    }
    
    return (
        <div className={`modal fade ${style.e_invite_modal}`}
            id="invite-modal"
            tabIndex="-1"
            aria-labelledby="invite-modal"
            aria-hidden="true"
            data-bs-backdrop="true" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content padding-32px-lr padding-32px-top pb-4 ${style.e_content} `}>
                    <div className="d-flex justify-content-between align-items-center ">
                        <p className="color-jett-black e-poppins-semi-bold e-font-18 mb-0">Invite family member</p>
                        <Icon icon="close"
                            id="modal-close"
                            data-bs-dismiss="modal"
                            size={24}
                            className="cursor-pointer e-modal-close-btn" />
                    </div>
                    {
                        verified === false ?
                            <div className="row margin-42px-top">
                                <div className="col-6">
                                    {/* input field to enter master trust id */}
                                    <CustomTextInput value={mastertrustId}
                                        label="Add mastertrust ID"
                                        postfix="*"
                                        error={mastertrustIdError}
                                        handleChange={(e) => _handleInputField(1, e)} />
                                </div>
                                <div className="col-6">
                                    {/* input field to enter pan number */}
                                    <CustomTextInput value={panNumber}
                                        label="PAN number"
                                        postfix="*"
                                        inputClass="text-uppercase"
                                        pan={true}
                                        maxLength={10}
                                        error={panNumberError}
                                        handleChange={(e) => _handleInputField(2, e)} />
                                </div>
                                <div className="col-6 mt-4">
                                    <CustomPhoneInput
                                        label="Mobile"
                                        postfix="*"
                                        isMobileInput={true}
                                        error={phoneError}
                                        value={phoneNumber}
                                        type='number'
                                        handleChange={_handleInput} />
                                </div>
                                <div className="col-12">
                                    <p className="color-jett-black e-poppins-regular e-font-14 margin-32px-bottom mt-4">We will send out an invitation RSVP to the concern person email/phone.
                                        Upon approval the user details will be updated on your profile.</p>
                                </div>
                                <div className="col-12 text-center">
                                    <GradientButton label="Send invite"
                                        loading={apiLoader}
                                        className="e-font-16 padding-12px-tb mx-5 w-75"
                                        handleClick={_handleValidation} />
                                </div>
                            </div>
                            :
                            <div className="row margin-42px-top">

                                <p className="color-outer-space e-poppins-regular e-font-14 mb-4 margin-20px-top">
                                    Enter OTP you have received in phone number
                                </p>

                                <div className="d-flex flex-column align-items-center text-start">
                                    <OTPInput pinString={pinString}
                                        id="sip-otp"
                                        autoFocus={true}
                                        pinError={pinError}
                                        handleChange={_handlePin} />


                                    <GradientButton label="Submit"
                                        loading={apiLoader}
                                        className="w-100 padding-14px-tb margin-32px-top"
                                        handleClick={_verifyPin} />

                                    <div className="d-flex justify-content-center mt-4">
                                        {
                                            seconds === 0 ?
                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-pointer mb-0 e-forgot-pin position-relative w-max-content'
                                                    onClick={_handleResend}>Resend OTP</p>
                                                :
                                                <p className='color-primary-color e-poppins-regular e-font-14 line-height-14px cursor-not-allowed mb-0 '>
                                                    Resend OTP in <span className="color-black">{seconds}s</span>
                                                </p>
                                        }

                                    </div>
                                </div>
                            </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default InviteMemberModal